html {
	/* background-image: url(/images/background.png); */
	background-color: white;
}
	body {
		/* background-image: url(/images/background.png); */
		background-color: white;
		font-family: "Raleway",sans-serif;
		font-size: 24px;
		font-weight:400;
		color: #333;
		max-width:1000px;
		margin: auto;
	}

	label{
		color:#722283 !Important;
	}

	.md-select-placeholder{
		color: #722283 !Important;
	}
md-checkbox.md-checked.compromiso .md-icon {
	background-color: #ffd300;
}

md-select:not([disabled]):focus .md-select-value {
	color: #111;
	border: solid 1px #ffd300;
}

input:not([disabled]):focus .md-select-value {
	color: #111;
	border: solid 1px #ffd300;
}
md-checkbox .md-icon {
	background: white;
}

md-checkbox.md-default-theme.md-checked .md-icon,
md-checkbox.md-checked .md-icon {
	background-color: #722283;
}

md-select.md-default-theme .md-select-value.md-select-placeholder,
md-select .md-select-value.md-select-placeholder,
md-input-container.md-default-theme .md-input,
md-input-container .md-input,
md-input-container label {
	color: #111;
}

.md-select-value .md-select-icon {
	color: #111;
}

md-select:not([disabled]):focus .md-select-value {
	color: #111;
	border: solid 1px #722283;
}

input:not([disabled]):focus .md-select-value {
	color: #111;
	border: solid 1px #722283;
}

md-input-container:not(.md-input-invalid).md-input-focused label {
	color: #111;
}

md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label,
md-input-container:not(.md-input-invalid).md-input-has-value label {
	color: #111;
}

md-input-container .md-errors-spacer {
	min-height: 0px;
}

.caja {
	border: solid 2px white;
	padding: 0px 20px 0px 20px;
	margin: 10px;
}

.comprabtn {
	background-color: #feed3b;
	padding: 10px 30px 10px 30px;
	color: black;
	font-weight: bold;
	margin: 10px;
}

a {
	text-decoration: none;
}

.tarifa {
	margin: 20px;
	font-size: 14px;
	text-align: center;
}

.md-select-value>span:not(.md-select-icon) .md-text {
	color: black;
}

.black {
	color: black !Important;
}

.cuotaSel{
	color: #722283;
    font-weight: bold;
}
.login {
	background-color: white;
}
/*
md-sidenav {
  Ajustar la barra lateral al 100% height: 100%;
  position: absolute !Important;
}*/

md-item-content {
	position: relative;
}

.notupdated {
	color: red;
}
.httpspinner {
	position: fixed;
	top: 0;
	z-index: 10000;
}
.empleado {
	background-color: rgb(144, 202, 249);
	font-size: 12px;
}

md-grid-tile div md-grid-tile-footer {
	height: 20%;
}